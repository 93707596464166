/* eslint-disable global-require */
import universal from 'react-universal-component';
import { setupRouter } from '@cotyorg/ccx-server/router';
import { safeImport } from '@cotyorg/ccx-server/utils';

const AuthentischeBewertungen = universal(
  import('./pages/AuthentischeBewertungen')
);
const ErrorPage = universal(import('./pages/Error'));
const NotFound = universal(import('./pages/NotFound'));
const Home = universal(import('./pages/Home'));
const Category = universal(import('./pages/Category'));
const Subcategory = universal(import('./pages/Subcategory'));
const Trends = universal(import('./pages/Trends'));
const Article = universal(import('./pages/Article'));
const Product = universal(import('./pages/Product'));
const Couch = universal(import('./pages/Couch'));
const About = universal(import('./pages/About'));
const Tutorials = universal(import('./pages/Tutorials'));
const SearchResults = universal(import('./pages/SearchResults'));
const New = universal(import('./pages/New'));
const Impressum = universal(import('./pages/Impressum'));
const VirtualTryOn = universal(import('./pages/VirtualTryOn'));
const TermsAndConditions = universal(import('./pages/TermsAndConditions'));
const Nutzungsbedingungen = universal(import('./pages/Nutzungsbedingungen'));
const Teilnahmebedingungen = universal(import('./pages/Teilnahmebedingungen'));
const SocialMediaHouseRules = universal(
  import('./pages/SocialMediaHouseRules')
);
const AnimalTesting = universal(import('./pages/AnimalTesting'));
const KindAndFree = universal(import('./pages/KindAndFree'));
const MadameWeb = universal(import('./pages/MadameWeb'));
const Parfum = universal(import('./pages/Parfum'));

const routerConfig = {
  helperComponents: {
    ErrorComponent: ErrorPage,
  },

  defaultPages: {
    homeOverrides: {
      path: '/:locale',
      component: Home,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/home').default
      ),
    },
    notFoundOverrides: {
      component: NotFound,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/not-found').default
      ),
    },
    categoryOverrides: {
      path: '/:locale/:category',
      component: Category,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/category').default
      ),
    },
    subcategoryOverrides: {
      path: '/:locale/:category/:subcategory',
      component: Subcategory,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/subcategory').default
      ),
    },
    productOverrides: {
      path: '/:locale/:category/:subcategory/:product',
      component: Product,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/product').default
      ),
    },
  },

  additionalRoutes: [
    {
      path: '/:locale/uber-uns',
      exact: true,
      id: 'about',
      title: 'About',
      component: About,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/about').default
      ),
    },
    {
      path: '/:locale/trends',
      exact: true,
      id: 'trends',
      title: 'Trends',
      component: Trends,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/trends').default
      ),
    },
    {
      path: '/:locale/tutorials',
      exact: true,
      id: 'tutorials',
      title: 'Tutorials',
      component: Tutorials,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/tutorials').default
      ),
    },
    {
      path: '/de-de/couch',
      exact: true,
      id: 'couch',
      title: 'Couch',
      component: Couch,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/couch').default
      ),
    },
    {
      path: '/:locale/tutorial/:slug',
      exact: true,
      id: 'Tutorial',
      title: 'Tutorial',
      component: Article,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/article').default
      ),
    },
    {
      path: '/:locale/trends/:slug',
      exact: true,
      id: 'Trends',
      title: 'Trends',
      component: Article,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/article').default
      ),
    },
    {
      path: '/de-de/couch/:slug',
      exact: true,
      id: 'couch',
      title: 'Couch',
      component: Article,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/article').default
      ),
    },
    {
      path: '/:locale/search',
      exact: false,
      id: 'search-results-page',
      title: 'Search Results Page',
      component: SearchResults,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/search-results').default
      ),
    },
    {
      path: '/:locale/neu',
      exact: true,
      id: 'new',
      title: 'New',
      component: New,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/new').default
      ),
    },
    // This path is only to be used by ReactRouter Config, accessing this page directly is an edge case
    {
      path: '/:locale/error',
      exact: true,
      id: 'error',
      title: 'Error',
      component: ErrorPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/error').default
      ),
    },
    {
      path: '/:locale/impressum',
      exact: true,
      id: 'impressum',
      title: 'Impressum',
      component: Impressum,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/impressum').default
      ),
    },
    {
      path: '/:locale/virtual-try-on',
      exact: true,
      id: 'virtual-try-on',
      title: 'Virtual Try On',
      component: VirtualTryOn,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/virtual-try-on-page').default
      ),
    },
    {
      path: '/:locale/terms-and-conditions',
      exact: true,
      id: 'terms-and-conditions',
      title: 'Terms And Conditions',
      component: TermsAndConditions,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/terms-and-conditions').default
      ),
    },
    {
      path: '/de-de/nutzungsbedingungen',
      exact: true,
      id: 'nutzungsbedingungen',
      title: 'Nutzungsbedingungen',
      component: Nutzungsbedingungen,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/nutzungsbedingungen').default
      ),
    },
    {
      path: '/:locale/teilnahmebedingungen',
      exact: true,
      id: 'teilnahmebedingungen',
      title: 'Teilnahmebedingungen',
      component: Teilnahmebedingungen,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/teilnahmebedingungen').default
      ),
    },
    {
      path: '/:locale/social-media-house-rules',
      exact: true,
      id: 'social-media-house-rules',
      title: 'Social Media House Rules',
      component: SocialMediaHouseRules,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/social-media-house-rules').default
      ),
    },
    {
      path: '/:locale/animal-testing',
      exact: true,
      id: 'animal-testing',
      title: 'Animal Testing Page',
      component: AnimalTesting,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/animal-testing').default
      ),
    },
    {
      path: '/:locale/clean-and-free',
      exact: true,
      id: 'kind-and-free',
      title: 'Kind and Free',
      component: KindAndFree,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/kind-and-free').default
      ),
    },
    {
      path: '/:locale/authentische-bewertungen',
      exact: true,
      id: 'authentische-bewertungen',
      title: 'Authentische Bewertungen',
      component: AuthentischeBewertungen,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/authentische-bewertungen').default
      ),
    },
    /*
    {
      path: '/:locale/parfum',
      exact: true,
      id: 'parfum',
      title: 'Parfum',
      component: Parfum,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/parfum').default
      ),
    },
    */
  ],

  routesToRemove: [
    // 'example', // NB: there is no example route, so this won't remove anything - but this is the mechanism
  ],
};

export default setupRouter(routerConfig);
