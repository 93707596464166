import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import BrowserRouter from 'react-router-dom/BrowserRouter';
import BreakpointProvider from '@cotyorg/ccx-utils/js/components/BreakpointProvider';
import configureStore from './state/store';
import App from './App';
import breakpoints from '../styles/_breakpoints.scss';

// Main stylesheet - TODO we should split this up to get benefit of component splitting better
import '../styles/main.scss';

const preloadedState = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// Allow the passed state to be garbage-collected
// https://redux.js.org/recipes/server-rendering#the-client-side
delete window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

const store = configureStore(preloadedState);

const supportsHistory = 'pushState' in window.history;

const renderApp = () => {
  const MOUNT_POINT = document.getElementById('app');

  hydrate(
    <Provider store={store}>
      <BreakpointProvider breakpoints={breakpoints}>
        <BrowserRouter forceRefresh={!supportsHistory}>
          <App />
        </BrowserRouter>
      </BreakpointProvider>
    </Provider>,
    MOUNT_POINT
  );
};

renderApp();
